export default {
  // 举报投诉
  workComplaint: {
    add: 'backend/php/complaint/add',
    getSmsCode: 'backend/php/complaint/getSmsCode',
    upload: 'backend/php/complaint/upload',
    checkCode: 'backend/php/complaint/checkCode',
    dataIndex: 'backend/php/complaint/dataIndex',
    cancel: 'backend/php/complaint/cancel',
    getCountyList: 'backend/php/complaint/getCountyList'
  }
}
