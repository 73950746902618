import paths from './path'
//  引入axios
import $axios from 'axios'
// import qs from 'qs'
import domains from './domains'
const headers = {
  'Content-type': 'application/x-www-form-urlencoded'
}
$axios.defaults.baseURL = process.env.NODE_ENV === 'production'
  ? domains.api
  : '/'
$axios.defaults.withCredentials = true
// 投诉举报表单创建
function addWorkComplaint (data) {
  return new Promise((resolve, reject) => {
    $axios.post(`${paths.workComplaint.add}`, data, headers).then(res => {
      const { data } = res
      resolve(data)
    }).catch(e => {
      reject(e)
    })
  })
}
// 验证码获取
function getSmsCode (data) {
  return new Promise((resolve, reject) => {
    $axios.post(`${paths.workComplaint.getSmsCode}`, data, headers).then(res => {
      const { data } = res
      resolve(data)
    }).catch(e => {
      reject(e)
    })
  })
}
// 文件上传
function uploadFile (data) {
  return new Promise((resolve, reject) => {
    $axios.post(`${paths.workComplaint.upload}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
      }
    }
    ).then(res => {
      const { data } = res
      resolve(data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 我的列表验证码验证
function checkCode (data) {
  return new Promise((resolve, reject) => {
    $axios.post(`${paths.workComplaint.checkCode}`, data, headers).then(res => {
      const { data } = res
      resolve(data)
    }).catch(e => {
      reject(e)
    })
  })
}

// C端举报列表
function dataIndex (data) {
  return new Promise((resolve, reject) => {
    $axios.post(`${paths.workComplaint.dataIndex}`, data, headers).then(res => {
      const { data } = res
      resolve(data)
    }).catch(e => {
      reject(e)
    })
  })
}

// C端举报列表
function cancelComplaint (data) {
  return new Promise((resolve, reject) => {
    $axios.post(`${paths.workComplaint.cancel}`, data, headers).then(res => {
      const { data } = res
      resolve(data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 接收单位
function getCountyList (data) {
  return new Promise((resolve, reject) => {
    $axios.post(`${paths.workComplaint.getCountyList}`, data, headers).then(res => {
      const { data } = res
      resolve(data)
    }).catch(e => {
      reject(e)
    })
  })
}

export {
  addWorkComplaint,
  getSmsCode,
  uploadFile,
  checkCode,
  dataIndex,
  cancelComplaint,
  getCountyList
}
